import { getTranslation } from "../utils/i18n";
import de from "../shared/variables/deDocuments.json";
import mime from "mime-types";
export function jsonToBlob(json) {
    const jsonStr = JSON.stringify(json, null, 2);
    const blob = new Blob([jsonStr], { type: "application/json" });
    return blob;
}
export async function fileToJson(file) {
    if (typeof FileReader !== "undefined") {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                try {
                    const content = JSON.parse(reader.result);
                    resolve(content);
                }
                catch (error) {
                    reject(error);
                }
            };
            reader.onerror = () => reject(reader.error);
            reader.readAsText(file);
        });
    }
    if (typeof Buffer !== "undefined") {
        const buffer = Buffer.from(await file.arrayBuffer());
        const json = JSON.parse(buffer.toString("utf8"));
        return json;
    }
    return {};
}
export function getFileExtensionByMimeType(mimeType) {
    return mime.extension(mimeType) || "unknown";
}
export function getFileExtensionByDataUrl(dataUrl) {
    const mime = dataUrl.match(/:(.*?);/)?.at(1) ?? "";
    return getFileExtensionByMimeType(mime);
}
export function downloadBlob(blob, fileName) {
    const url = isDataUrl(blob) ? blob : window.URL.createObjectURL(blob);
    const a = window.document.createElement("a");
    window?.document?.body?.appendChild(a);
    a.style.cssText = "display: none";
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
}
export default function isBlob(value) {
    if (typeof Blob === "undefined") {
        return false;
    }
    return value instanceof Blob || toString.call(value) === "[object Blob]";
}
export function dataURLtoBlob(dataurl) {
    const arr = dataurl.split(",");
    const mime = arr
        .at(0)
        ?.match(/:(.*?);/)
        ?.at(1);
    const u8arr = dataURLtoArrayBuffer(dataurl);
    return new Blob([u8arr], { type: mime });
}
export function dataURLtoArrayBuffer(dataurl) {
    const arr = dataurl.split(",");
    const bstr = atob(arr[1]);
    const n = bstr.length;
    const u8arr = new Uint8Array(n);
    for (let i = 0; i < n; i++) {
        u8arr[i] = bstr.charCodeAt(i);
    }
    return u8arr;
}
export async function blobToDataUrl(blob) {
    const emptyData = "data:";
    if (!blob || !isBlob(blob)) {
        return emptyData;
    }
    if (typeof FileReader !== "undefined") {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = () => reject(reader.error);
            reader.onabort = () => reject(new Error("Read aborted"));
            reader.readAsDataURL(blob);
        });
    }
    if (typeof Buffer !== "undefined") {
        const buffer = Buffer.from(await blob.arrayBuffer());
        const dataUrl = `data:${blob.type};base64,${buffer.toString("base64")}`;
        return dataUrl;
    }
    return emptyData;
}
export function isDataUrl(dataUrl) {
    return typeof dataUrl === "string" && dataUrl.startsWith("data:");
}
export function fileNameByDocumentCategory(category) {
    const { t } = getTranslation(de);
    const today = new Date();
    return `${t(`category.${category ?? "other"}.label`)}-${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}.pdf`;
}
export function hash(text) {
    let hash = 5381;
    let index = text.length;
    while (index) {
        hash = (hash * 33) ^ text.charCodeAt(--index);
    }
    return hash >>> 0;
}
export function shortHash(text) {
    let hash = 5381;
    let index = text.length;
    while (index) {
        hash = (hash * 33) ^ text.charCodeAt(--index);
    }
    return (hash >>> 0).toString(36);
}
