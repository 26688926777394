import type { DeepPartial, DotNotatedPropertyAccess } from "@/global"
import { hash } from "api/dist/utils/data"
import { isBrowser } from "api/dist/utils/env"
import { sanitizeHtml } from "@/utils/sanitize"
import { assign, get } from "radashi"
import type {
  ExistsFunction,
  I18nLocaleTextBundle,
  PluralExtensions,
  TranslateFunction,
} from "./types"
import { fallbackLocale } from "./constants"

const warnedOnce: Record<string, boolean> = {}

export const getSupportedRegionCodes = (locale: string) =>
  ({ de: ["DE"] })[locale]

export const getFallbackLocaleWithRegion = () =>
  `${fallbackLocale}-${getSupportedRegionCodes(fallbackLocale)?.at(0)}`

export const _applyVariables = (
  translation: string,
  variables: Record<string, string | number>,
) => {
  if (!variables) return translation
  return Object.entries(variables ?? {}).reduce(
    (translation, [variable, value]) =>
      translation.replaceAll(`{{${variable}}}`, `${value}`),
    translation,
  )
}

export function _exists<T extends I18nLocaleTextBundle>(messages: T) {
  const existsFunction: ExistsFunction<T> = (key: string) => {
    const translation = get(messages, key, undefined)

    return !!translation
  }

  return existsFunction
}

export function _translate<
  T extends I18nLocaleTextBundle,
  K extends keyof DotNotatedPropertyAccess<
    T,
    PluralExtensions
  > = keyof DotNotatedPropertyAccess<T, PluralExtensions>,
>(messages: T, overwriteMessages?: DeepPartial<T>) {
  const translateFunction: TranslateFunction<T, K> = (
    key: K | K[],
    variables: Record<string, string | number> = {},
  ) => {
    const keySequence = Array.isArray(key) ? key : [key]
    const mergedMessages = overwriteMessages
      ? assign(messages, overwriteMessages)
      : messages

    for (const key of keySequence) {
      const pluralVariable = "count" in variables

      // check if plural key exists
      if (pluralVariable && Number(variables.count) === 0) {
        const translationOne = get(mergedMessages, `${key}_zero`, undefined)

        if (translationOne) {
          return _applyVariables(translationOne, variables)
        }
      } else if (pluralVariable && Number(variables.count) === 1) {
        const translationOne = get(mergedMessages, `${key}_one`, undefined)

        if (translationOne) {
          return _applyVariables(translationOne, variables)
        }
      } else if (pluralVariable && Number(variables.count) !== 1) {
        const translationOther = get(mergedMessages, `${key}_other`, undefined)

        if (translationOther) {
          return _applyVariables(translationOther, variables)
        }
      }

      const translation = get(mergedMessages, key, undefined)

      if (!translation || typeof translation !== "string") {
        // if key is not the last key in the sequence, continue with next key
        const isLastKeyInSequence = key === keySequence[keySequence.length - 1]
        if (!isLastKeyInSequence) continue

        // print missing translation
        if (!warnedOnce[key] && isBrowser()) {
          console.warn(`😱 Missing translation for ${key}`)
          warnedOnce[key] = true
        }
        return key
      }

      return _applyVariables(translation, variables)
    }

    // print missing translation
    if (isBrowser()) {
      console.error("😱 No translation keys passed to translate function.")
    }
    return ""
  }

  return translateFunction
}

// biome-ignore lint/suspicious/noExplicitAny: required to be agnostic
export const _tHtml = <T extends (...args: any[]) => string>(
  t: T,
  getDocument?: (message: string) => Document,
) => {
  const tHtml = (...args: Parameters<typeof t>) => {
    const message = t(...args)

    const cleanedHtml = sanitizeHtml(
      message,
      {
        allowedTags: [
          "kbd",
          "code",
          "b",
          "i",
          "em",
          "strong",
          "a",
          "br",
          "p",
          "ul",
          "ol",
          "li",
        ],
        allowedAttributes: {
          a: ["href", "target"],
        },
      },
      getDocument?.(message),
    )

    return (
      <span
        key={hash(cleanedHtml)}
        // biome-ignore lint/security/noDangerouslySetInnerHtml: safe as we sanitize the html
        dangerouslySetInnerHTML={{ __html: cleanedHtml }}
      />
    )
  }
  tHtml.displayName = "tHtml"

  return tHtml
}
