import { assign, get } from "radashi";
export const fallbackLocale = "de";
export const getSupportedRegionCodes = (locale) => ({ de: ["DE"] })[locale];
export const getFallbackLocaleWithRegion = () => `${fallbackLocale}-${getSupportedRegionCodes(fallbackLocale)?.at(0)}`;
const warnedOnce = {};
const _applyVariables = (translation, variables) => {
    if (!variables)
        return translation;
    return Object.entries(variables ?? {}).reduce((translation, [variable, value]) => translation.replaceAll(`{{${variable}}}`, `${value}`), translation);
};
function _exists(messages) {
    const existsFunction = (key) => {
        const translation = get(messages, key, undefined);
        return !!translation;
    };
    return existsFunction;
}
function _translate(messages, overwriteMessages) {
    const translateFunction = (key, variables = {}) => {
        const keySequence = Array.isArray(key) ? key : [key];
        const mergedMessages = overwriteMessages
            ? assign(messages, overwriteMessages)
            : messages;
        for (const key of keySequence) {
            const pluralVariable = "count" in variables;
            // check if plural key exists
            if (pluralVariable && Number(variables.count) === 1) {
                const translationOne = get(mergedMessages, `${key}_one`, undefined);
                if (translationOne) {
                    return _applyVariables(translationOne, variables);
                }
            }
            else if (pluralVariable && Number(variables.count) !== 1) {
                const translationOther = get(mergedMessages, `${key}_other`, undefined);
                if (translationOther) {
                    return _applyVariables(translationOther, variables);
                }
            }
            const translation = get(mergedMessages, key, undefined);
            if (!translation || typeof translation !== "string") {
                // if key is not the last key in the sequence, continue with next key
                const isLastKeyInSequence = key === keySequence[keySequence.length - 1];
                if (!isLastKeyInSequence)
                    continue;
                return key;
            }
            return _applyVariables(translation, variables);
        }
        return "";
    };
    return translateFunction;
}
/**
 * to be used in utility functions
 */
export const getTranslation = (messages, overwriteMessages) => {
    const t = _translate(messages, overwriteMessages);
    const exists = _exists(messages);
    return { t, exists };
};
/**
 * to be used in server components
 */
export const serverTranslation = async (messages, overwriteMessages) => {
    const t = _translate(messages, overwriteMessages);
    const exists = _exists(messages);
    return { t, exists };
};
